import { AppEnvironment } from '@shure/cloud/shared/utils/config';
import { LogLevel } from '@shure/shared/angular/utils/logging';

import { environment as devEnvironment } from './environment.dev';

export const environment: AppEnvironment = {
	...devEnvironment,
	sysApi: {
		url: 'https://system-api.dev.shure.services/graphql',
		apiKey: ''
	},
	logLevel: LogLevel.Trace
};
