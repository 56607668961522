import { CommonModule } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { NgHttpCachingService } from 'ng-http-caching';
import { catchError, combineLatest, map, of, Subject, takeUntil } from 'rxjs';

import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { BreakpointService } from '@shure/cloud/shared/services/media-breakpoints';
import { SwitchOrganizationService } from '@shure/cloud/shared/switch-organization/feature-switch-organization';
import { HeaderToolbarModule } from '@shure/cloud/shared/ui/components';
import { CloseTextOption, SnackbarService } from '@shure/cloud/shared/ui/components';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { SidenavComponent } from '../../app/sidenav/sidenav.component';

@Component({
	selector: 'sh-app-shell',
	standalone: true,
	imports: [CommonModule, RouterModule, MatSidenavModule, SidenavComponent, HeaderToolbarModule],
	templateUrl: './app-shell.component.html',
	styleUrl: './app-shell.component.scss'
})
export class AppShellComponent implements OnInit {
	public readonly isSmallDevice = this.breakpointService.isLteSmall;
	public showNotifications = true;
	private destroy$ = new Subject<boolean>();

	constructor(
		private readonly injector: Injector,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private orgSwitcherService: SwitchOrganizationService,
		private snackbarService: SnackbarService,
		private readonly translocoService: TranslocoService,
		public readonly oktaIntf: OktaInterfaceService,
		private breakpointService: BreakpointService,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment,

		private ngHttpCachingService: NgHttpCachingService,
		private readonly logger: ILogger
	) {
		this.logger = logger.createScopedLogger('CDM AppShell');
		this.showNotifications = Boolean(this.appEnv.cdmFeatureFlags?.showNotificationsInHeader);

		monitorLoginState(oktaIntf, {
			onLogIn: this.onLogIn.bind(this),
			onLogOut: this.onLogOut.bind(this)
		});
	}

	public ngOnInit(): void {
		this.logger.debug('CDM', 'ngOnInit');
	}

	public onLogIn(): void {
		this.ngHttpCachingService.clearCache();
		this.destroy$ = new Subject();
		if (!this.appEnv.cdmFeatureFlags?.showOrgSwitcher) {
			return;
		}
		const requestedOrgId$ = this.activatedRoute.queryParamMap.pipe(
			map((queryParamMap) => queryParamMap.get('orgId')),
			// remove possible leading/training quotes
			map((orgId) => orgId?.replace(/["']/g, '') ?? ''),
			takeUntil(this.destroy$)
		);
		const currentOrgId$ = this.oktaIntf.getUserProfile$().pipe(
			map((userProfile) => userProfile.orgId),
			takeUntil(this.destroy$)
		);
		const allOrgIds$ = this.orgSwitcherService.getAllMyOrganizations().pipe(
			map((orgData) => {
				const items = orgData.body.body?.items;
				if (!items) {
					return [];
				}
				return items.map((item) => item.id ?? '');
			}),
			catchError(() => of([])),
			takeUntil(this.destroy$)
		);

		combineLatest([requestedOrgId$, currentOrgId$, allOrgIds$])
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: ([requestedOrgId, currentOrgId, allOrgIds]) => {
					this.checkAndSwitchOrgs(requestedOrgId, currentOrgId, allOrgIds);
				}
			});
	}
	public onLogOut(): void {
		this.logger.debug('CDM', 'onLogout');
	}

	private checkAndSwitchOrgs(requestedOrgId: string, currentOrgId: string, allOrgIds: string[]): void {
		// no requested org, can't switch
		if (!requestedOrgId || requestedOrgId.length === 0) {
			return;
		}

		// already on this org... no reason to switch
		if (requestedOrgId === currentOrgId) {
			// remove orgId query parameter
			this.router.navigate(['/']);
			return;
		}

		// if the requested Org Id is not a valid org for the user put up a snackbar,
		// then remove the orgId parameter from the URL by routing to '/' w/out the query parameter
		if (!allOrgIds.includes(requestedOrgId)) {
			this.snackbarService.open(
				this.translocoService.translate('cloud.shared.switch-org.not-a-member-of-org'),
				CloseTextOption.Dismiss
			);
			this.router.navigate(['/']);
			return;
		}

		// switch to requested Org
		this.logger.debug('Switching orgs', 'New value %o', requestedOrgId);
		this.oktaIntf
			.setOktaSessionTenant$(requestedOrgId, false)
			.pipe(
				map(() => true),
				catchError(() => {
					return of(false);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((result: boolean) => {
				if (result) {
					// it was decided to not keep the orgId in the URL.
					// route to / to remove it, then reload the page.
					this.router.navigate(['/']).then(() => {
						window.location.reload();
					});
				}
			});
	}
}
