<ng-container id="notification-container">
	<div id="sh-notification-details-content-div" *transloco="let t">
		<div id="sh-notification-details-subject-div">
			{{ notificationData.subject }}
		</div>
		<div id="sh-notification-details-content-div">
			@if (notificationData.sentAt) {
				<div>
					<div id="sh-notification-details-received-label-div" class="sh-notification-details-subheading">
						{{ t('cloud.shared.notification.received') }}
					</div>
					<p id="sh-notification-details-sent-at-p" class="sh-notification-details-subheading-content">
						{{ formatTime(notificationData.sentAt) }}
					</p>
				</div>
			}
			<div>
				<div id="sh-notification-details-organization-label-div" class="sh-notification-details-subheading">
					{{ t('cloud.shared.notification.organization') }}
				</div>
				<p id="sh-notification-details-organization-name-p" class="sh-notification-details-subheading-content">
					{{ notificationData.organization?.name }}
				</p>
				<mat-divider></mat-divider>
			</div>
			<div>
				<div
					*ngIf="notificationData.body"
					id="sh-notification-details-body-content-div"
					[innerHTML]="notificationData.body"
				></div>
			</div>
		</div>
	</div>
	<div style="padding-bottom: 10px; overflow: hidden" *transloco="let t">
		<div class="sh-notification-details-buttons">
			<button mat-button color="accent" (click)="closePopup()" id="sh-notification-details-close-button">
				{{ t('cloud.shared.notification.close') }}
			</button>
			<button
				*ngIf="notificationListType === 'inbox'"
				mat-button
				color="accent"
				(click)="markAsTrash(notificationData.id)"
				id="sh-notification-details-move-to-trash-button"
			>
				{{ t('cloud.shared.notification.move-to-trash') }}
			</button>
		</div>
	</div>
</ng-container>
