<ng-container *ngFor="let notification of notifications$ | async" id="notification-container">
	<div id="sh-notification-details-content-div" *transloco="let t">
		<div id="sh-notification-details-subject" class="item-style">
			{{ notification.subject }}
		</div>
		<div id="sh-notification-details-content-div">
			<div>
				<div id="received-label" class="sh-notification-details-subheading">
					{{ t('cloud.shared.notification.received') }}
				</div>
				<p id="sent-at" class="sh-notification-details-subheading-content">
					{{ notification.sentAt | date: 'hh:mm a  MMM-d-yyyy' }}
				</p>
			</div>
			<div>
				<div id="received-label" class="sh-notification-details-subheading">
					{{ t('cloud.shared.notification.organization') }}
				</div>
				<p id="sent-at" class="sh-notification-details-subheading-content">
					{{ notification?.organization?.name }}
				</p>
				<mat-divider></mat-divider>
			</div>
			<div>
				<div *ngIf="notification.body" [innerHTML]="notification.body"></div>
			</div>
		</div>
	</div>
	<div style="padding-bottom: 10px; overflow: hidden" *transloco="let t">
		<div class="sh-notification-details-buttons">
			<button mat-button color="accent" (click)="closePopup()" id="sh-notification-details-close-button">
				{{ t('cloud.shared.notification.close') }}
			</button>
			<button
				*ngIf="isListPage"
				mat-button
				color="accent"
				(click)="markAsArchive(notification.id)"
				id="sh-notification-details-archive-button"
			>
				{{ t('cloud.shared.notification.archive') }}
			</button>
			<button
				*ngIf="!isListPage"
				mat-button
				color="accent"
				(click)="markAsRead(notification.id)"
				id="sh-notification-details-clear-button"
			>
				{{ t('cloud.shared.notification.clear') }}
			</button>
			<button mat-button color="accent" (click)="closePopup()" id="sh-notification-details-go-to-app-button">
				{{ t('cloud.shared.notification.go-to-app') }}
			</button>
		</div>
	</div>
</ng-container>
