export interface RTUData {
	data: {
		id: string;
		message: string;
		sender: {
			firstName: string;
			lastName: string;
			email: string;
		};
		readAt: string;
		sentAt: string;
		isSetPreferences: boolean;
	};
}

export const CHUNK_SIZE = 100;
