import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SystemService } from '@shure/cloud/device-management/devices/data-access';
import { ConnectInfoProviderValue } from '@shure/cloud/shared/apollo';

import { environment } from '../../environments/environment';

const localStorageKeyPrefix = 'shure.sysapi';
type LocalStorageKey = 'url' | 'apiKey' | 'useWsTransport';

@Injectable()
export class CloudSystemService implements SystemService {
	/**
	 * Get System API connection info. Any values that have been set in local storage supersede the
	 * corresponding values from the environment.
	 */
	public getSysApiConnectInfo(): Observable<ConnectInfoProviderValue> {
		const url = this.getValueFromLocalStorage('url') ?? environment.sysApi?.url ?? 'missing_from_env';
		const wsUrl = url.replace('https', 'wss').replace('http', 'ws');
		const apiKey = this.getValueFromLocalStorage('apiKey') ?? environment.sysApi?.apiKey ?? 'missing_from_env';

		const temp = this.getValueFromLocalStorage('useWsTransport');
		const useWsTransport = temp !== null ? this.convertToBoolean(temp) : environment.sysApi?.useWsTransport ?? true;

		return of({
			url: url,
			wsUrl: wsUrl,
			apiKey: apiKey,
			useWsTransport: useWsTransport
		});
	}

	public isSystemReady(): Observable<boolean> {
		return of(true);
	}

	private getValueFromLocalStorage(key: LocalStorageKey): string | null {
		const prefixedKey = `${localStorageKeyPrefix}.${key}`;
		return window.localStorage.getItem(prefixedKey);
	}

	private convertToBoolean(value: string): boolean {
		return value.toLowerCase() === 'true' || value === '1';
	}
}
