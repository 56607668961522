import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadge } from '@angular/material/badge';
import { MatIconButton, MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { catchError, EMPTY, map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';

import {
	GetNotificationByIdResponse,
	NotificationsEndpointApiService
} from '@shure/cloud/shared/data-access/notifications';
import { NotificationsStore, NotificationStoreData } from '@shure/cloud/shared/notifications-store';
import { CloseTextOption, formatToUserSpecificTime, SnackbarService } from '@shure/cloud/shared/ui/components';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
export enum MessageCenterNotificationsListType {
	INBOX = 'inbox',
	TRASH = 'trash'
}

@Component({
	selector: 'sh-notification-details',
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		MatListModule,
		MatSlideToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatTabsModule,
		FormsModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatInputModule,
		TranslocoModule,
		MatIcon,
		MatIconButton,
		MatBadge,
		MatButtonModule,
		MatDivider
	],
	templateUrl: './notification-details.component.html',
	styleUrls: ['./notification-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class NotificationDetailsComponent implements OnInit, OnDestroy {
	public environment: AppEnvironment;
	public isListPage = false;
	public notificationListType = '';
	public notificationData!: NotificationStoreData;
	public notifications$: Observable<NotificationStoreData[] | null> = EMPTY;
	public formatTime;
	public readonly destroy$ = new Subject<void>();
	protected readonly messageCenterNotificationsListType = MessageCenterNotificationsListType;

	constructor(
		private dialogRef: MatDialogRef<NotificationDetailsComponent>,
		private notificationService: NotificationsEndpointApiService,
		private notificationsStore: NotificationsStore,
		public snackBarService: SnackbarService,
		private translocoService: TranslocoService,
		public changeDetectorRef: ChangeDetectorRef,
		@Inject(APP_ENVIRONMENT) appEnv: AppEnvironment,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			notificationData: NotificationStoreData;
			isListPage: boolean;
			shouldMarkAsReadOnOpen: boolean;
			notificationListType: string;
		}
	) {
		this.environment = appEnv;
		this.formatTime = formatToUserSpecificTime;
	}

	/**
	 * Initializes component and loads notification details
	 */
	public ngOnInit(): void {
		this.notificationData = this.data.notificationData;
		this.notificationListType = this.data.notificationListType;
		this.isListPage = this.data.isListPage;
		if (!this.notificationData.readAt) {
			this.markAsRead(this.notificationData.id);
		}
	}

	/**
	 * Closes the dialog
	 */
	public closePopup(): void {
		this.dialogRef.close();
	}

	/**
	 * Marks a notification as archived
	 * @param selectedId The ID of the notification to archive
	 */
	public markAsArchive(selectedId: string): void {
		const requestData = {
			application: <string>this.environment.application,
			body: [
				{
					id: selectedId,
					isArchived: true
				}
			]
		};

		this.notificationService
			.postNotifications(requestData)
			.pipe(
				switchMap(() => this.notifications$),
				take(1),
				map((notifications): NotificationStoreData[] => {
					return (
						notifications?.map((notification) =>
							selectedId.includes(notification.id)
								? { ...notification, archivedAt: this.getCurrentDateInUTC() }
								: notification
						) || []
					);
				}),
				catchError(() => of([])),
				takeUntil(this.destroy$)
			)
			.subscribe((updatedNotifications) => {
				this.notificationsStore.prependOrPatchNotifications(updatedNotifications);
				this.notifications$ = of(<GetNotificationByIdResponse[]>updatedNotifications);
				this.dialogRef.close();
			});
	}

	/**
	 * Marks a notification as read
	 * @param selectedId The ID of the notification to mark as read
	 */
	public markAsRead(selectedId: string): void {
		const data = {
			id: selectedId,
			isRead: true,
			isTrashed: this.notificationListType === this.messageCenterNotificationsListType.TRASH ? true : false
		};
		const requestData = {
			application: <string>this.environment.application,
			body: [data]
		};
		this.notificationService
			.postNotifications(requestData)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.notificationsStore.prependOrPatchNotifications([
						{ ...this.notificationData, readAt: this.getCurrentDateInUTC() }
					]);
				}
			});
	}

	public extractTextFromHtml(item?: string): string {
		return item ? item.replace(/<[^>]*(>|$)| |‌|»|«|>/g, ' ') : '';
	}

	/**
	 * Deletes the notification by closing the dialog
	 */
	public deleteNotification(): void {
		this.dialogRef.close();
	}

	/**
	 * Gets the current date in UTC format
	 * @returns The current date in UTC format as a string
	 */
	public getCurrentDateInUTC(): string {
		const currentDate = new Date(new Date().getTime() + 10000);
		return currentDate.toISOString().slice(0, 19) + 'Z';
	}

	/**
	 * Performs cleanup when the component is destroyed
	 */
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public markAsTrash(selectedId: string): void {
		const data = {
			id: selectedId,
			isRead: true,
			isTrashed: true
		};
		const requestData = {
			application: <string>this.environment.application,
			body: [data]
		};

		this.notificationService
			.postNotifications(requestData)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.notificationsStore.prependOrPatchNotifications([
						{ ...this.notificationData, trashedAt: this.getCurrentDateInUTC() }
					]);
					this.snackBarService.open(
						this.translocoService.translate('cloud.shared.notification.moved-to-trash'),
						CloseTextOption.Dismiss
					);
					this.closePopup();
				},
				error: () => {
					this.snackBarService.open(
						this.translocoService.translate('cloud.shared.notification.unable-to-move-to-trash'),
						CloseTextOption.Dismiss
					);
				}
			});
	}
}
