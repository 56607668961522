import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { some, get, every, forEach } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PermissionsApiService } from '@shure/cloud/shared/permissions/data-access';
import { OrganizationsStoreService } from '@shure/cloud/shared/services/organizations-store-service';
import { CloseTextOption, SnackbarService } from '@shure/cloud/shared/ui/components';

export interface ShPermissionsAggregate {
	$and?: string[];
	$or?: string[];
}

@Injectable({
	providedIn: 'root'
})
export class PermissionsService {
	private userSession!: { string: boolean };
	public userRole!: string;

	constructor(
		private apiPermissionsService: PermissionsApiService,
		private snackBarService: SnackbarService,
		private translocoService: TranslocoService,
		private organizationsStoreService: OrganizationsStoreService
	) {}

	public getPermissions$(): Observable<{ string: boolean }> {
		return this.apiPermissionsService.getPermissions$Response().pipe(
			map((response) => {
				const responseData = JSON.parse(<string>(<unknown>response.body));
				this.userSession = responseData.body[0]?.permissions;
				this.organizationsStoreService.storePermissions(this.userSession);
				return responseData.body[0]?.permissions;
			}),
			catchError((error) => {
				const errorResponse = JSON.parse(error.error);
				if (errorResponse?.i18nKey) {
					this.snackBarService.open(
						this.translocoService.translate(`cloud.shared.error-labels.${errorResponse.i18nKey}`),
						CloseTextOption.Ok
					);
				} else {
					this.snackBarService.open('Unable to retrieve permissions', CloseTextOption.Ok);
				}
				return of(error);
			})
		);
	}
	public getAdminPermissions$(): Observable<{ string: boolean }> {
		return this.apiPermissionsService.getAdminPermissions$Response().pipe(
			map((response) => {
				const responseData = JSON.parse(<string>(<unknown>response.body));
				this.userSession = responseData.body[0]?.permissions;
				this.userRole = responseData.body[0]?.role;
				return responseData.body[0]?.permissions;
			})
		);
	}
	public testPermissions(requiredPermissions: string | ShPermissionsAggregate): boolean {
		if (!this.userSession) {
			return false;
		}

		if (!requiredPermissions) {
			return false;
		}

		return typeof requiredPermissions === 'string'
			? this.testPermission(requiredPermissions)
			: this.testManyPermissions(requiredPermissions);
	}

	public testPermission(requiredPermission: string): boolean {
		return get(this.userSession, requiredPermission) === true;
	}

	private testManyPermissions(requiredPermissions: ShPermissionsAggregate): boolean {
		let hasAccessToFeature = false;
		let hasAccess = false;
		const combination: boolean[] = [];

		if (requiredPermissions.$or) {
			forEach(requiredPermissions.$or, (feature: string) => {
				hasAccessToFeature = get(this.userSession, feature) === true;
				combination.push(hasAccessToFeature);
			});
			hasAccess = some(combination, Boolean);
		} else if (requiredPermissions.$and) {
			forEach(requiredPermissions.$and, (feature: string) => {
				hasAccessToFeature = get(this.userSession, feature) === true;
				combination.push(hasAccessToFeature);
			});
			hasAccess = every(combination, Boolean);
		}

		return hasAccess;
	}
}
