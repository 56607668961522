<ng-container *transloco="let t">
	<div id="sh-notification-popup-header-div">
		<div id="sh-notification-popup-content-div">
			<div id="sh-notification-popup-text-div">
				<p id="sh-notification-popup-title">{{ t('cloud.shared.notification.title') }}</p>
				<p id="sh-notification-popup-current-date">{{ currentDate | date: 'MMMM d, y' }}</p>
			</div>
			<mat-icon
				id="sh-notification-popup-close-icon"
				fontSet="prism-mat"
				fontIcon="close"
				(click)="closedialog()"
			></mat-icon>
		</div>
		<div id="sh-notification-popup-buttons-div" class="sh-notification-popup-buttons">
			<button mat-button color="accent" (click)="notificationsList()" id="sh-notification-popup-view-all-button">
				{{ t('cloud.shared.notification.view-all') }}
			</button>
			<button
				[disabled]="(notifications$ | async)?.length === 0"
				mat-button
				color="accent"
				(click)="clearAll()"
				id="sh-notification-popup-close-button"
			>
				{{ t('cloud.shared.notification.mark-all-as-read') }}
			</button>
		</div>
	</div>
	<p id="sh-notifications-list-loading-message" class="sh-loading-style" *ngIf="(notifications$ | async) === null">
		{{ t('cloud.shared.notification.loading') }}
	</p>
	<div
		id="sh-notification-list-container"
		class="sh-notification-list-container"
		*ngIf="(notifications$ | async)?.length !== 0"
	>
		<prism-list id="sh-notification-list">
			<prism-list-item
				label="list-item"
				*ngFor="let notification of notifications$ | async; let i = index"
				class="sh-notification-popup-list-item"
				id="sh-notification-popup-list-item-{{ i }}"
			>
				<div class="sh-notification-popup-list-item-div">
					<div class="sh-notification-popup-dot"></div>
					<div class="sh-notification-popup-list-content" (click)="openPopup(notification)">
						<div class="sh-notification-popup-organization-name">
							{{ t('cloud.shared.notification.organization') | uppercase }}:
							{{ notification?.organization?.name | uppercase }}
						</div>
						<div class="sh-notification-popup-subject">{{ notification.subject }}</div>
						<div class="sh-notification-popup-body">{{ notification.body }}</div>
					</div>
					<prism-icon-button
						class="sh-notification-popup-clear-icon"
						id="sh-notification-popup-clear-icon-{{ i }}"
						icon="remove_circle_outline"
						label="clear"
						size="medium"
						(click)="markAsRead(notification)"
					></prism-icon-button>
					<div mat-line class="sh-notification-popup-custom-date">
						{{ notification.sentAt }}
					</div>
				</div>
			</prism-list-item>
		</prism-list>
	</div>
	<div id="sh-notification-popup-no-notification" *ngIf="(notifications$ | async)?.length === 0">
		<p>{{ t('cloud.shared.notification.no-notification') }}</p>
	</div>
</ng-container>
<mat-divider></mat-divider>
